import React from "react";
import { useLocation } from "@reach/router";

import { $isuuid, $UUID } from "foundation-ts/commons";

import { TemporaryAuthenticatedLayout } from "../components/layouts/TemporaryAuthenticatedLayout";
import { ConfirmNewEmailView } from "../containers/userProfile/ConfirmNewEmailView";

import { changeEmail } from "../services/users.service";

const ConfirmNewEmailPage = () => {
    const { search } = useLocation() ;
    const searchParams = new URLSearchParams(search) ;
    const userId = searchParams.get("userId") ;

    return (
        <TemporaryAuthenticatedLayout getData={async (token: string) => { 
            if (!$isuuid(userId)) { return null ; }
            return await changeEmail($UUID(userId)!, token)! ;
        }}>
            <ConfirmNewEmailView />
        </TemporaryAuthenticatedLayout>
    ) ;
}

export default ConfirmNewEmailPage ;