import { useToast } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import React, { useEffect } from "react";

import { $ok } from "foundation-ts/commons";

import { TemporaryChildProps } from "../../components/layouts/TemporaryAuthenticatedLayout";

import { config } from "../../config/config";
import { defaultErrorToast, defaultInfoToast, defaultSuccessToast } from "../../utils/toast";

export const ConfirmNewEmailView = ({ sharedData } : TemporaryChildProps) => {
    const toast = useToast() ;

    useEffect(() => {
        if ($ok(sharedData)) {
            toast($ok(sharedData) ? defaultSuccessToast( "Votre changement d'email a bien été pris en compte. A la prochaine connexion, c'est la nouvelle adresse mail qu'il faudra utiliser.") : defaultErrorToast("Impossible de changer votre adresse mail"));
            toast(defaultInfoToast("Vous allez être automatiquement redirigé vers la page d'authentification.")) ;
            const timer = setTimeout(() => {
                navigate(config.app.loginURL!) ;
            }, 2000) ;

            return () => clearTimeout(timer) ;
        }
    }, [sharedData]) ;

    return <></> ;
} ;